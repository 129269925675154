import React from "react";
import styles from "./Testimonial1.module.css";
import TSlide1 from "./TestimonialSlider/TSlide1";
import TSlide111 from "./TestimonialSlider/TSlide111";
import TSlide2 from "./TestimonialSlider/TSlide2";
import TSlide3 from "./TestimonialSlider/TSlide3";
import TSlide4 from "./TestimonialSlider/TSlide4";
import Slider from "react-slick";

const Testimonial = () => {
  const LeftArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-358.svg";
  const RightArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-359.svg";
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        onClick={onClick}
        alt=""
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "40px",
          left: "-50px"
        }}
        src={LeftArrow}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={RightArrow}
        className={className}
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "40px",
          right: "-50px"
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    adaptiveHeight: true,
    autoplay: false,
    speed: 3000,
    arrows: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          arrows: true
        }
      }
    ]
  };



  

  return (
    <>
      <style jsx>{`
        .slick-slide {
          margin: 0 10px; /* Adjust the margin to control space between slides */
        }

        .slick-list {
          margin: 0 -10px; /* Adjust to compensate for the slick-slide margin */
        }

        .container {
          max-width: 1200px; /* Adjust the max-width as needed */
          margin: 0 auto; /* Center the container */
          padding: 0 20px; /* Optional padding for better alignment */
        }
      `}</style>
      <div className={styles.stories} style={{ margin: "0" }}>
        <div className={styles.frameParent}>
          <div className={styles.discoverWhy100Container}>
            Discover why{" "}
            <span className="h1">
              <b> 100+ Clients</b>
            </span>{" "}
            rave about our services
          </div>
          <div className="container">
            <div className={styles.clientReview}>
              <div className={styles.frameContainer}>
                <TSlide111 />
                <div className="testimonial--content--holder">
                  <div className={styles.group}>
                    <Slider {...settings}>
                      <div>
                        <TSlide2 />
                      </div>
                      <div>
                        <TSlide1 />
                      </div>
                      <div>
                        <TSlide3 />
                      </div>
                      <div>
                        <TSlide4 />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
