import React from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

const DropdownMenuIT = ({ sendDataToParent }) => {
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };
  const handleMouseLeave = () => {
    const data = false;
    sendDataToParent(data);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 50 }}
      className="container-fluid position-absolute margin-top-20"
      style={{
        backgroundColor: "#E9EEF4",
        zIndex: "1",
        top: "18%",
        left: "42%",
        width: "20%",
 
        borderRadius: "0px 0px 0px 0px",
      }}

      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem" }}>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3 gap-2"  style={{listStyle:"none", marginBottom:"-1rem"}}>
          <li className="mb-3">
              <Link to="/about-us" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              About Us
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Contact Us
              </Link>
            </li>
            {/* <li className="mb-3">
              <Link to="/career" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Careers
              </Link>
            </li> */}
 
          </ul>
        </div>
     
      </div>
    </motion.div>
  );
};

export default DropdownMenuIT;
