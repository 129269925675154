import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case1.module.css";
import styless from "../components/ContactUsBar.module.css";
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Helmet } from "react-helmet";


const MainBlogs = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
            <Helmet>
<meta charSet="utf-8" />
<title>Stay Updated with Techrowth's Digital Marketing Blog
</title>
<meta name="description" content="Engaged in digital marketing? Then follow Techrowth Digital blog, one of Bangalore's top sources for the latest digital marketing updates." />
</Helmet>
        <div className={styles1.newHomepage}>
      <div className="container" style={{ padding: "2rem 2rem" }}>
        <div className={styles.resourcePage24}>
        <div className={styles.sliderParent}>
          
          <div className={styles.slider}>
            <div className={styles.groupParent}>
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
            </div>
          </div>
        


          <div className={styles.executionStrategy} >
             Our Blogs:
            </div>


            <div className={styles.blog}>
            
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
               
               <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blog-4-2.jpg" />
  
         
               <div style={{padding:"1.5rem"}}>
               <Link to="/ppc-budgeting-and-bid-strategies-maximizing-roi-for-your-campaigns" className="text-white" style={{textDecoration:"none"}}>
           <h4 style={{color:"#0076a2"}}>  PPC Budgeting and Bid Strategies: Maximizing ROI for Your Campaigns
           </h4> </Link>
           <h6> <span style={{color:"black"}}>Are you tired of spending money on PPC services without seeing the results you want? It's time to take control of your budgeting and bid strategies to maximize your ROI. In this blog post, we will explore how you can make the most out of the money spent on a PPC company in Bangalore. Whether you're a newbie or a seasoned pro, these tips and tricks will help you achieve success with digital marketing companies in Bangalore. Let's dive in and start making every click count!
     </span></h6>
    
  
           <div className={styles.blogInner} style={{marginTop:"2rem"}}>
             <div className={styles.continueReadingWrapper}>
             <Link to="/ppc-budgeting-and-bid-strategies-maximizing-roi-for-your-campaigns" className="text-white" style={{textDecoration:"none"}}>
                  <div className={styles.continueReading}>Continue Reading</div>
                  </Link>
             </div>
           </div>
               </div>
           </div>
            </div>

            
            <div className={styles.blog}>
            
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
               
               <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blog-3-2.jpg" />
  
         
               <div style={{padding:"1.5rem"}}>
               <Link to="/ppc-vs-seo-choosing-the-right-digital-marketing-strategy-for-your-business" className="text-white" style={{textDecoration:"none"}}>
           <h4 style={{color:"#0076a2"}}>  PPC vs SEO: Which Strategy is Right for Your Business?
           </h4> </Link>
           <h6> <span style={{color:"black"}}> In today's digital age, having a strong online presence is crucial for businesses to thrive. Two key strategies that can help boost your visibility and drive traffic to your website are Pay-Per-Click (PPC) advertising and Search Engine Optimization (SEO). But how do you know which strategy is right for your business? In this blog post, we'll break down the differences between PPC and SEO, so you can make an informed decision on which one will best suit your marketing goals. Let's dive in!
     </span></h6>
    
  
           <div className={styles.blogInner} style={{marginTop:"2rem"}}>
             <div className={styles.continueReadingWrapper}>
             <Link to="/ppc-vs-seo-choosing-the-right-digital-marketing-strategy-for-your-business" className="text-white" style={{textDecoration:"none"}}>
                  <div className={styles.continueReading}>Continue Reading</div>
                  </Link>
             </div>
           </div>
               </div>
           </div>
            </div>

            <div className={styles.blog}>
            
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
               
               <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blog-2-1.jpg" />
  
         
               <div style={{padding:"1.5rem"}}>
               <Link to="/voice-search-seo-strategies-for-local-businesses-optimizing-for-the-future" className="text-white" style={{textDecoration:"none"}}>
           <h4 style={{color:"#0076a2"}}>Voice Search SEO Strategies for Local Businesses: Optimizing for the Future
           </h4> </Link>
           <h6> <span style={{color:"black"}}> In a world where voice search is quickly becoming the preferred method of finding information, local businesses need to adapt this voice search seo strategies in order to stay ahead of the game. In this blog post, we will explore how you can optimize your website for voice search, attract more customers and dominate the local search results. Get ready to revolutionize your SEO game and set yourself up for success in the future!
     </span></h6>
    
  
           <div className={styles.blogInner} style={{marginTop:"2rem"}}>
             <div className={styles.continueReadingWrapper}>
             <Link to="/voice-search-seo-strategies-for-local-businesses-optimizing-for-the-future" className="text-white" style={{textDecoration:"none"}}>
                  <div className={styles.continueReading}>Continue Reading</div>
                  </Link>
             </div>
           </div>
               </div>
           </div>
            </div>


            <div className={styles.blog}>
            
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
               
               <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blog-1-img-2.jpg" />
  
         
               <div style={{padding:"1.5rem"}}>
               <Link to="/rise-of-ai-in-digital-marketing-how-businesses-are-leveraging-smart-technology" className="text-white" style={{textDecoration:"none"}}>
           <h4 style={{color:"#0076a2"}}>The Rise of AI in Digital Marketing: How Businesses Are Leveraging Smart Technology
           </h4> </Link>
           <h6> <span style={{color:"black"}}>     In the ever-evolving world of digital marketing, one trend is taking center stage: artificial intelligence. From chatbots to personalized content recommendations, businesses are increasingly turning to AI technology to revolutionize their marketing strategies. But what exactly does this mean for the future of marketing? How are digital marketing companies in Bangalore leveraging smart technology to reach new heights of success? ...
     </span></h6>
    
  
           <div className={styles.blogInner} style={{marginTop:"2rem"}}>
             <div className={styles.continueReadingWrapper}>
             <Link to="/rise-of-ai-in-digital-marketing-how-businesses-are-leveraging-smart-technology" className="text-white" style={{textDecoration:"none"}}>
                  <div className={styles.continueReading}>Continue Reading</div>
                  </Link>
             </div>
           </div>
               </div>
           </div>
            </div>

            
          <div className={styles.blog}>
            



          <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/blog18.png" />

       
             <div style={{padding:"1.5rem"}}>
             <Link to="/role-of-ai-in-digital-marketing" className="text-white" style={{textDecoration:"none"}}>
         <h4 style={{color:"#0076a2"}}>Role of AI in Digital Marketing</h4> </Link>
         <h6> <span style={{color:"black"}}>      It's not surprising that artificial intelligence (AI) has become a hot subject in the realm of digital marketing given how quickly technology is developing. AI is rapidly changing how brands interact with customers online, from chatbots and voice assistants to personalized content recommendations. In this blog post, we will look at how AI is altering digital marketing and why companies should adopt this ground-breaking technology if they want to stay competitive. The future is already here when it comes to the usage of AI in digital marketing, so get ready for a sneak peek!
   </span></h6>
  

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/role-of-ai-in-digital-marketing" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
          </div>



     
          <div />
        </div>
          
          <div className={styles.sidebar}>
            <div className={styles.sidebarform}>
              <div className={styles.frameParent8}>
                <div className={styles.letsConnectParent}>
                  <div className={styles.executionStrategy}>Let’s Connect</div>
                  <div className={styles.frameParent9}>
                    <input
                      className={styles.frameInput}
                      type="text"
                      placeholder="Full Name"
                      required
                    />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Designation"
                    />
                  <input
        className={styles.frameInput}
        type="text"
        placeholder="Official Contact No."
        name="phoneno"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
      />
              <input
                    className={styles.frameChild7}
                    type="email"
                    placeholder="Business Email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Company Name"
                    />
                     <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Website"
                    />
                    <div className={styles.squareRadioWrapper}>
              <p style={{color:"var(--blue)"}}>Interested Service -</p>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="digitalmarketing"
              value="Digital_Marketing"
              required
            />
            Digital Marketing
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="itdevelopment"
              value="IT_Development"
              required
            />
            IT Development
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="analytics"
              value="Analytics"
              required
            />
            Analytics
          </label>
        </div>
                    <input
                      className={styles.frameChild10}
                      type="text"
                      placeholder="Message"
                    />
                  </div>
                </div>
                <div className={styles.frameWrapper4}>
                  <button className={styles.submitWrapper}>
                    <div className={styles.submit}>Submit</div>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.sidebarcontactdetails}>
              <div className={styles.sidebarcontactdetailsInner}>
                <div className={styles.frameParent10}>
                  <div className={styles.frameWrapper5}>
                    <div className={styles.frameParent11}>
                      <img
                        className={styles.frameChild1}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-572%402x.png"
                      />
                      <div className={styles.contactUsParent}>
                        <div
                          className={styles.executionStrategy}
                        >{`Contact Us `}</div>
                                  <a href="tel:+918073162943" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>India: +91 80731 62943</p></a>
          <a href="tel:+260767527931" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Zambia: +260 767527931</p></a>
          <a href="tel:+610402049350" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Australia: +61 04020 49350</p></a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineDiv} />
                  <div className={styles.contactform11}>

                    <h4>Global Support</h4>
                    <a href="mailto:info@techrowth.com" target="_blank" style={{color:"white", textDecoration:"none"}}><p style={{fontSize:"13px"}}>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Frame+3050.svg" 
                    />info@techrowth.com</p></a>
                    <a href="https://join.skype.com/invite/pR5oMI4Q8bAy" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697123.svg" 
                    />Skype Techrowth</p></a>
                    <a href="https://teams.live.com/l/invite/FEAzL5s01dTUWIILwE" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild111}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2721123.svg"

                    />Teams  Techrowth</p></a>
                      <a  target="_blank" style={{color:"white", textDecoration:"none", cursor:"pointer"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697-123.svg"
                    />+91 935-393-7262</p></a>
                    </div>




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default MainBlogs;


