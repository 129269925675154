import React, { useState, useEffect, useRef } from 'react';
import styles from "./QuantifyingTheData.module.css";
import { Link } from "react-router-dom";
import NumberCounter from 'number-counter';
import { useInView } from 'react-intersection-observer';

const handleWhatsAppRedirect = () => {
  const phoneNumber = "+918073162943";
  const url = `https://wa.me/${phoneNumber}`;
  window.open(url, "_blank");
};

const QuantifyingTheData = () => {
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <>
    <section className='desktop-quantifydata'>
      <div ref={ref} className={styles.quantifyingTheData}>
        <div className={styles.quantifyingTheDataParent}>
          <b className={styles.quantifyingTheData1} style={{fontWeight:"600"}}>Quantifying the Data</b>
          <div className={styles.frameParent}>
            <div className={styles.weSetTheStandardForExcellParent}>
              <div className={styles.weSetTheContainer}>
                <p className={styles.weSetThe}>
                  We set the standard for excellence, delivering impressive results and wowing
                </p>
                <p className={styles.weSetThe}>
                  customers with the Best Digital Marketing Services in Bangalore.
                </p>
              </div>
              <div className={styles.frameGroup}>
                <div className={styles.parent}>
                  {inView && (
                    <NumberCounter end={24} delay={1} className={styles.div} postFix="%" />
                  )}
                  <div className={styles.revenueIncrement}>
                    <p className={styles.weSetThe}>Revenue</p>
                    <p className={styles.weSetThe}>Increment</p>
                  </div>
                </div>
                <div className={styles.parent}>
                  {inView && (
                    <NumberCounter end={11} delay={1} className={styles.div1} postFix="%" />
                  )}
                  <button className={styles.incrementSocialMediaContainer}>
                    <p className={styles.weSetThe}>Increment Social</p>
                    <p className={styles.weSetThe}>Media Interaction</p>
                  </button>
                </div>
                <div className={styles.parent}>
                  {inView && (
                    <NumberCounter end={36} delay={1} className={styles.div3} postFix="%" />
                  )}
                  <div className={styles.customerRetention}>
                    <p className={styles.weSetThe}>Customer</p>
                    <p className={styles.weSetThe}>Retention</p>
                  </div>
                </div>
              </div>
              <div className={styles.letsAccomplishGreat}>
                Let's accomplish great things together
              </div>
            </div>
            <button className={styles.getInTouchWrapper} onClick={handleWhatsAppRedirect}>
              <div className={styles.getInTouch}>Get In Touch</div>
            </button>
          </div>
        </div>
      </div>

      {/* Additional Section */}
      <div className={styles.quantifyingTheData100}>
        <h4 className={styles.businessAnalystics23}>Quantifying the data</h4>
        <div className={styles.quantifyingTheData33}>
          <div className={styles.quantifyingTheData43}>
            <div className={styles.quantifyingTheData53}>
              {inView && <NumberCounter end={24} delay={1} className={styles.quantifyingTheData63} />}
              <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Revenue Increment</p>
            </div>
            <div className={styles.quantifyingTheData53}>
              {inView && <NumberCounter end={11} delay={1} className={styles.quantifyingTheData63} />}
              <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Increment Social Media Interaction</p>
            </div>
            <div className={styles.quantifyingTheData53}>
              {inView && <NumberCounter end={36} delay={1} className={styles.quantifyingTheData63} />}
              <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Customer Retention</p>
            </div>
          </div>
          <p className={styles.quantifyingTheData303}>Let's accomplish great things together</p>
          <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
            <button className={`${styles.letsGetStartedWrapper} w-auto mb-3`} style={{borderRadius:"30px", padding:".5rem 1rem", color:"#0076A2", background:"white", border:"none", textAlign:"center"}}>
              <div className={styles.letsGetStarted} style={{fontWeight:"700"}}>Contact Us</div>
            </button>
          </Link>
        </div>
      </div>


      </section>





<section className='mobile-quantifydata'>


<div className={styles.quantifyingTheData}>
      <div className={styles.quantifyingTheDataParent}>
        <b className={styles.quantifyingTheData1} style={{fontWeight:"600"}}>Quantifying the Data</b>
        <div className={styles.frameParent}>
          <div className={styles.weSetTheStandardForExcellParent}>
            <div className={styles.weSetTheContainer}>
              <p
                className={styles.weSetThe}
              >{`We set the standard for excellence, delivering impressive results and wowing`}</p>
              <p className={styles.weSetThe}>
              customers with the Best Digital Marketing Services in Bangalore.
              </p>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.parent}>
              {/* <NumberCounter end={24} delay={1} className={styles.div} postFix="%"/> */}
                <div className={styles.div}>24%</div>
                <div className={styles.revenueIncrement}>
                  <p className={styles.weSetThe}>Revenue</p>
                  <p className={styles.weSetThe}>Increment</p>
                </div>
              </div>
              <div className={styles.parent}>
              {/* <NumberCounter end={11} delay={1} className={styles.div1} postFix="%"/> */}
                <div className={styles.div1}>11%</div>
                <button className={styles.incrementSocialMediaContainer}>
                  <p className={styles.weSetThe}>Increment Social</p>
                  <p className={styles.weSetThe}>Media Interaction</p>
                </button>
              </div>
              <div className={styles.parent}>
              {/* <NumberCounter end={22} delay={1} className={styles.div2} postFix="%"/> */}

                <div className={styles.div2}>22%</div>
                <div className={styles.conversionRate}>
                  <p className={styles.weSetThe}>Conversion</p>
                  <p className={styles.weSetThe}>Rate</p>
                </div>
              </div>
              <div className={styles.parent}>
              {/* <NumberCounter end={36} delay={1} className={styles.div1} postFix="%"/> */}

                <div className={styles.div3}>36%</div>
                <div className={styles.customerRetention}>
                  <p className={styles.weSetThe}>Customer</p>
                  <p className={styles.weSetThe}>Retention</p>
                </div>
              </div>
            </div>
            <div className={styles.letsAccomplishGreat}>
              Let's accomplish great things together
            </div>
          </div>
          <button className={styles.getInTouchWrapper}  onClick={handleWhatsAppRedirect}>
            <div className={styles.getInTouch}>Get In Touch</div>
          </button>
        </div>
      </div>
    </div>
    <div className={styles.quantifyingTheData100}>
    <h4 className={styles.businessAnalystics23}>Quantifying the data</h4>

    <div className={styles.quantifyingTheData33}>
      <div className={styles.quantifyingTheData43}>
        <div className={styles.quantifyingTheData53}>
          <strong><p className={styles.quantifyingTheData63}>24%</p></strong>
          <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Revenue Increment</p>
        </div>
        <div className={styles.quantifyingTheData53}>
          <strong><p className={styles.quantifyingTheData63}>11%</p></strong>
          <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Increment Social Media Interaction</p>
        </div>
        <div className={styles.quantifyingTheData53}>
          <strong><p className={styles.quantifyingTheData63}>36%</p></strong>
          <p className={styles.quantifyingTheData73} style={{fontSize:"12px"}}>Customer Retention</p>
        </div>
      </div>
      <p className={styles.quantifyingTheData303}>Let's accomplish great things together</p>
      <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>
      <button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{borderRadius:"30px",padding:".5rem 1rem", color:"#0076A2",background:"white", border:"none",textAlign:"center"}}>
                <div className={styles.letsGetStarted} style={{fontWeight:"700"}}>Contact Us</div>
              </button>
</Link>
    </div>
</div>





</section>










    </>
  );
};

export default QuantifyingTheData;
