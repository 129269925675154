import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"5rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">
<div className="row">
<div className="col-lg-8">
<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Maximizing Digital Customer Acquisition in the Banking Sector


</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full img-fluid" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-101.png" alt="D" width="800" height="422"  sizes="(max-width: 800px) 100vw, 800px"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
A Banking Sector</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The business wanted to increase their number of digital account holders through new customer acquisition and reduce the cost associated with <Link to="https://techrowth.com/digital-marketing">digital marketing</Link> facilities.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>
Increased new account holders by 18%.</p>



<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>
<p>During the course of this campaign, we were able to help the client by utilizing a set of rational <Link to="https://techrowth.com/digital-marketing">digital marketing strategies</Link> for client acquisition while staying within their budget.</p>
<p><strong>1) Strategic Selection of Relevant Media:</strong></p>
<ul>
<li>We chose to use Facebook and Google ads for the <Link to="https://techrowth.com/digital-marketing">digital marketing campaign</Link> to solve their problem of low customer acquisition numbers.</li>

</ul>
<p><strong>2) Research of Audience Set:</strong></p>
<ul>
<li>We conducted research to identify potential customers beyond an interest-based pool and considered competitors' audiences as potential clients.</li>

</ul>
<p><strong>3) Multiple Facebook Campaigns:</strong></p>
<ul>
<li>We conducted thorough and continuous research during the Facebook campaign and optimized it for maximum results.</li>


</ul>
<p><strong>4) Optimized Google Campaign:</strong></p>
<ul>
<li>We were able to identify and utilize multiple individual search groups looking for keywords like "online savings accounts" and "digital savings accounts," among others and also did competitor analysis for an optimized campaign. We also were able to identify the most efficient keywords to be used in their Google ads and launched a new, more optimized campaign for those keywords, leading to better results.</li>


</ul>
<p><strong>5) Ad Campaign for Visitors:</strong></p>
<ul>
<li>Launched targeted ad campaign for visitors of competitor websites to drive more traffic through Google.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>
<li>The <Link to="https://techrowth.com/digital-marketing">Digital Marketing Campaign</Link> lead to an increase of numbers in their new account by 18%.</li>
<li>The highest impact was brought on through the Facebook and Google campaigns; they yielded more than 89% of the total client registration during this campaign.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li>We used <Link to="https://techrowth.com/digital-marketing/social-media-marketing">social media marketing</Link> to increase brand awareness and engage with customers on various platforms.</li>

<li>PPC campaigns were utilized to target specific audiences and drive traffic to our website.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>The total cost of this campaign was around $13k, which encompasses the budget for the research team, the cost of running ads, data compilation, and all other associated costs.</li>

</ul>

</div>
<div id="secondary" className="widget-area col-sm-4" role="complementary" style={{borderLeft:"1px solid #e9eef4", height:"1600px"}}>

<div className="sidebar">
<div className="widgets case_studysidebar">
</div>
<div className="widgets">
<aside id="custom-post-type-recent-posts-7" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Recent Case Studies
</h5>			<ul style={{color:"#0076a2" }}>
<li>
<Link to="/discover-why-startups-shine-with-a-strong-social-media" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Discover Why Startups Shine With a Strong Social Media Presence - Insights for Success</p></Link>
</li>
<li>
<Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
               
              
               <p style={{color:"#0076a2"}}>Boosting and Expanding sales through Emotional Marketing.</p>  </Link>
</li>
<li>
<Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Overcoming low sales and Increasing brand Visibility through Rational Marketing.</p></Link>
</li>

<li>
<Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Revitalizing Engagement through Social media tools resulting in increased bookings.</p></Link>
</li>
<li>
<Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Transforming slow speed website through compression of file sizes.</p></Link>
</li>
<li>
<Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing B2B E-commerce
website for increased Sales &
Inquiries.</p></Link>
</li>
</ul>
</aside>			<aside id="custom-post-type-recent-posts-6" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Most Saved Case Studies</h5>			
			<ul style={{color:"#0076a2" }}>

<li>
<Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>By using IT services and
Analytics, Mid-Sized IT Tech
Company may increase
customer retention.</p></Link>
</li>
<li>
<Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Promoting a new menu Item to drive more sales for a hotel.</p></Link>
</li>
<li>
<Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing Online Presence and
Customer Engagement for an
Online Clothing Retailer.</p></Link>
</li>
<li>
<Link to="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Implemented Digital Marketing Strategies for Increasing Walk-ins in a Jewelry Store.</p></Link>
</li>
<li>
<Link to="/role-of-ai-in-digital-marketing" className="text-white" style={{textDecoration:"none"}}>
         <p style={{color:"#0076a2"}}>Role of AI in Digital Marketing</p> </Link>
</li>
</ul>
</aside>
</div>
</div>
</div>
</div>
</div>
</section>
</section>
</div>


    </>
  );
};

export default Test;
