import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

const Industries = () => {
  const cards = [
    { title: 'Development', description: ['We provide the most responsive and functional IT design for companies and businesses worldwide'] },
    { title: 'IT Management', description: ['It’s possible to simultaneously manage and transform information from one server to another'] },
    { title: 'Quality Testing', description: ['We ensure top-quality performance and reliability through rigorous software testing'] },
    { title: 'IT Support', description: ['Our IT support keeps your systems running smoothly and efficiently'] },
    { title: 'Network Audit', description: ['We provide thorough network audits to enhance security and performance'] },
    { title: 'UI/UX & Interactive', description: ['Website Design', 'Website Redesign'] },
    { title: 'E-commerce', description: ['We develop robust e-commerce platforms for seamless online shopping'] },
    { title: 'Business Intelligence', description: ['Our BI solutions turn data into actionable insights for your business.'] },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    
    <section className='industries-we-serve container pt-36 xl:pt-48 pb-24 md:pb-36' id="services">
       
      <div className='iws-content'>
        <div className='iws-content_left'>
          <div className='sticky-content'>
            
            <div className='industries-card'>
                <p className='text-white'> IT Support</p>
              <p className='text-white fs-30 montserrat-500'>
                <span className='montserrat-600'>"Our IT support keeps your systems running smoothly and efficiently"</span>
              </p>
            </div>
          </div>
        </div>
        <div className='iws-content_right'> 
            
          {cards.map((card, index) => (
            <CardWithMotion key={index} title={card.title} description={card.description} isMobile={isMobile} />
          ))}
        </div>
      </div>
    </section>
  );
};

const CardWithMotion = ({ title, description, isMobile }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const itemVariants = isMobile
    ? { // Animation for mobile screens
        hidden: { opacity: 0, y: 150 },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            ease: 'easeOut',
          },
        },
      }
    : { // No animation for larger screens
        opacity: 1,
        y: 0,
      };

  return (
    <motion.div
      ref={ref}
      className='iws-details-card'
      variants={itemVariants}
      initial='hidden'
      animate={inView ? 'visible' : 'hidden'}
    >
      <div className='dot-div'></div>
      <h3 className='black-text dark:text-white fs-21 py-4' style={{ textAlign: 'center' }}>{title}</h3>
      <p className='black-text dark:text-white fs-14' style={{ textAlign: 'center' }}>
        {description.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {index < description.length - 1 && <br />}
          </React.Fragment>
        ))}
      </p>
    </motion.div>
  );
};

export default Industries;
