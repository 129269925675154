import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"5rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">
<div className="row">
<div className="col-lg-8">
<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Implemented Digital Marketing Strategies for Increasing Walk-ins in a Jewelry Store
</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full img-fluid" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study9.png" alt="D" width="800" height="422"  sizes="(max-width: 800px) 100vw, 800px"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
A Jewelry business store.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The jewelry store wanted to increase their walk-Ins through <Link to="https://techrowth.com/digital-marketing">digital marketing</Link>.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>



144% Increase in Store Walk-Ins.</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>

<p><strong>1) Emotion-Based Marketing:</strong></p>
<ul>
<li>Utilized emotion-based marketing to tap into the emotional connection between customers and their friends, encouraging them to spread the word about the brand.</li>

</ul>
<p><strong>2) Utilizing Multiple Channels for Ads:</strong></p>
<ul>
<li>The company used Google, Facebook, and Display ads to boost both website sales and in- store visits, with the goal of increasing brand awareness and overcoming misconceptions about La b Grown Diamonds.</li>

</ul>
<p><strong>3) Google Local Ads:</strong></p>
<ul>
<li>Utilized Google Local Ads with targeted diamond-related keywords to direct potential customers to the nearest store location, while educating them about the store's offerings and making them feel welcomed. In addition, targeted display ads using in-market and affinity audiences to increase brand awareness and drive store visits.</li>


</ul>
<p><strong>4) Visual Content Creation:</strong></p>
<ul>
<li>The company created visually stunning content that showcased their products and culture, resonating with their target audience, using high-quality images, videos, and behind-the-scenes stories.</li>


</ul>
<p><strong>5) Incentivization Tactics:</strong></p>
<ul>
<li>Implemented a successful referral program and offered exclusive discounts to social media followers for increased engagement.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>
<li>Since the start of our campaigns, we have seen an increase of 144% in the number of walk-ins to stores.</li>
<li>This result was achieved within a time frame of two months.</li>
<li>A steady increase of 34% for their month-over-month walk-ins.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li>We used <Link to="https://techrowth.com/digital-marketing/social-media-marketing">social media marketing</Link> to increase brand awareness and engage with customers on various platforms.</li>
<li><Link to="https://techrowth.com/digital-marketing/pay-per-click">PPC campaigns</Link> were utilized to target specific audiences and drive traffic to our website.</li>
<li>Branding efforts were focused on creating a strong brand identity through consistent messaging and visual design.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>A $33k budget exclusive contract with us resulted in up to 34% customer engagement through various marketing channels.</li>

</ul>

</div>
<div id="secondary" className="widget-area col-sm-4" role="complementary" style={{borderLeft:"1px solid #e9eef4", height:"1500px"}}>

<div className="sidebar">
<div className="widgets case_studysidebar">
</div>
<div className="widgets">
<aside id="custom-post-type-recent-posts-7" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Recent Case Studies
</h5>			<ul style={{color:"#0076a2" }}>
<li>
<Link to="/discover-why-startups-shine-with-a-strong-social-media" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Discover Why Startups Shine With a Strong Social Media Presence - Insights for Success</p></Link>
</li>
<li>
<Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
               
              
               <p style={{color:"#0076a2"}}>Boosting and Expanding sales through Emotional Marketing.</p>  </Link>
</li>
<li>
<Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Overcoming low sales and Increasing brand Visibility through Rational Marketing.</p></Link>
</li>

<li>
<Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Revitalizing Engagement through Social media tools resulting in increased bookings.</p></Link>
</li>
<li>
<Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Transforming slow speed website through compression of file sizes.</p></Link>
</li>
<li>
<Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing B2B E-commerce
website for increased Sales &
Inquiries.</p></Link>
</li>
</ul>
</aside>			<aside id="custom-post-type-recent-posts-6" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Most Saved Case Studies</h5>			
			<ul style={{color:"#0076a2" }}>

<li>
<Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>By using IT services and
Analytics, Mid-Sized IT Tech
Company may increase
customer retention.</p></Link>
</li>
<li>
<Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Promoting a new menu Item to drive more sales for a hotel.</p></Link>
</li>
<li>
<Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing Online Presence and
Customer Engagement for an
Online Clothing Retailer.</p></Link>
</li>
<li>
<Link to="/maximizing-digital-customer-acquisition-in-the-banking-sector" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Maximizing Digital Customer
Acquisition in the Banking Sector.</p></Link>
</li>
<li>
<Link to="/role-of-ai-in-digital-marketing" className="text-white" style={{textDecoration:"none"}}>
         <p style={{color:"#0076a2"}}>Role of AI in Digital Marketing</p> </Link>
</li>
</ul>
</aside>
</div>
</div>
</div>
</div>
</div>
</section>
</section>
</div>


    </>
  );
};

export default Test;
