import { color, motion } from 'framer-motion';
import styles from "./DigitalMarketingServices1.module.css";

const DigitalMarketingServices1 = () => {
  // Define animation variants
  const leftFlow = {
    initial: { x: -100, opacity: 0 },
    animate: { x: 0, opacity: 1 },
  };

  const rightFlow = {
    initial: { x: 100, opacity: 0 },
    animate: { x: 0, opacity: 1 },
  };

  // Define blinking animation variants
  const blinkVariants = {
    hover: {
      backgroundColor: ['#fff', '#3a3535', '#0076a2'],
      transition: {
        duration: 0.2,
        repeat: 1,
        color: '#fff',
        repeatType: "reverse"
      },
      color: '#000'
    }
  };

  return (
    <>
      <div className={styles.digitalMarketingServices}>
        <div className="digitalMarketingServicesInner">
          <div className={styles.frameParent}>
            <div className={styles.digitalMarketingServicesParent}>
              <b className={styles.digitalMarketingServices1}>
                Digital Marketing Services
              </b>
              <div className={styles.intentWeServe}>Intent We serve</div>
            </div>
            <div className={styles.frameWrapper}>
              <motion.div
                className={styles.frameGroup}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  className={styles.parent}
                  initial="initial"
                  animate="animate"
                >
                  {/* First 5 cards flowing to the left */}
                  <motion.div
                    className={styles.div}
                    variants={leftFlow}
                    transition={{ delay: 0.1, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-34.svg"
                    />
                    <div className={styles.enquiry}>Enquiry</div>
                    <div className={styles.unleashYourBrandsContainer} >
                      <p className={styles.unleashYourBrands}>Unleash your brand's digital potential:</p>
                      <p className={styles.unleashYourBrands}>Discover, Convert, Succeed</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={leftFlow}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-35.svg"
                    />
                    <div className={styles.enquiry}>Branding</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Creating distinctive brands that leave a lasting impression</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={leftFlow}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-36.svg"
                    />
                    <div className={styles.enquiry}>Engagement</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Elevating brand engagement to new heights, one click at a time.</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={leftFlow}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-37.svg"
                    />
                    <div className={styles.enquiry}>Downloading</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Leveraging the Power of Downloads to Achieve Your Goals</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={leftFlow}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-38.svg"
                    />
                    <div className={styles.enquiry}>E-Commerce</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Streamlining your online business through our expert e-commerce solutions.</p>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                className={styles.frameGroup}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  className={styles.parent}
                  initial="initial"
                  animate="animate"
                >
                  {/* Second 5 cards flowing to the right */}
                  <motion.div
                    className={styles.div}
                    variants={rightFlow}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-39.svg"
                    />
                    <div className={styles.enquiry}>Upselling</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Experience premium up-selling services for faster growth.</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={rightFlow}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-40.svg"
                    />
                    <div className={styles.enquiry}>Cross-selling</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Enhancing your marketing efforts with targeted cross-selling.</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={rightFlow}
                    transition={{ delay: 0.8, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-41.svg"
                    />
                    <div className={styles.enquiry}>Remarketing</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Maximizing your ROI with our powerful remarketing services.</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={rightFlow}
                    transition={{ delay: 0.9, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants={blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-42.svg"
                    />
                    <div className={styles.enquiry}>Redownloading</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Reviving your customer base, Re-Download and reignite!</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className={styles.div}
                    variants={rightFlow}
                    transition={{ delay: 1.0, duration: 0.5 }}
                    whileHover="hover"
                    whileTap={{ scale: 0.95 }}
                    variants= {blinkVariants}
                  >
                    <img
                      className={styles.child}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-43.svg"
                    />
                    <div className={styles.enquiry}>Scaling</div>
                    <div className={styles.unleashYourBrandsContainer}>
                      <p className={styles.unleashYourBrands}>Scale your business with our digital marketing strategies.</p>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalMarketingServices1;
