



import React from 'react'

const About = () => {
  return (
    <section className='about-us-section container pt-40 pb-0' id='about'>
        <h2 className='black-text dark:text-white text-center fs-40 pb-16 montserrat-600' style={{ marginTop: '50px' }}>Analytic Services</h2>
        <div className='about-us-content flex space-between text-center'>
          <div className='about-us-content_left'>
            <p className='desc' style={{ marginTop: '20px'}}>
               
            Data-Driven Decisions, Thriving Businesses – Empowering Your

Business with Analytics
            </p>
          </div>
          <div className='about-us-content_right'>
            <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card-content"> 
                    <img
                   
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-572.svg"
                  />
                     <h3 className='black-text dark:text-white fs-24 pb-4 montserrat-600' style={{ marginTop: '50px', color: '#095274', fontSize: '20px' }}>
  Data Analytics
</h3>

                      
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card-content">
                    <img
                    
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-573.svg"
                  />
                      <h3 className='black-text dark:text-white fs-24 pb-4 montserrat-600' style={{ marginTop: '50px', color: '#095274', fontSize: '20px' }}>Data Visualization</h3>
                     
                    </div>
                  </div>
                </div>
            </div>




<div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card-content"> 
                    <img
                   
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/333.svg"
                  />
                     <h3 className='black-text dark:text-white fs-24 pb-4 montserrat-600' style={{ marginTop: '50px', color: '#095274', fontSize: '20px' }}>Business Analytics</h3>
                     
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card-content">
                    <img className='imageColor'
                    
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/444.svg"
                  />
                    <h3 className='black-text dark:text-white fs-24 pb-4 montserrat-600' style={{ marginTop: '50px', color: '#095274', fontSize: '20px' }}>
  Predictive Analytics
</h3>

                     
                    </div>
                  </div>
                </div>
            </div>






          </div>
        </div>
    </section>
  )
}

export default About