import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

const Industries1 = () => {
  const cards = [
    { title: 'Drive Website Traffic', description: ['We offer SEO, ecommerce, lead generation, and content marketing services to boost your online presence and drive growth'] },
    { title: 'Create & manage powerful ad campaigns', description: ['We specialize in PPC management, social advertising, and programmatic advertising services to optimize your digital campaigns and maximize ROI.'] },
    { title: 'Improve brand messaging and conversion', description: ['We offer Website Design Services, Website Accessibility, Landing Pages & Funnels UI/UX Website '] },
    { title: 'Collect, analyze and deploy marketing data', description: ['Our analytics solutions encompass a range of tools, including Social Media Analytics, Zoho Analytics, and Power BI, allowing us to extract valuable insights from diverse data sources'] },
    // { title: 'Network Audit', description: ['We provide thorough network audits to enhance security and performance'] },
    // { title: 'UI/UX & Interactive', description: ['Website Design', 'Website Redesign'] },
    // { title: 'E-commerce', description: ['We develop robust e-commerce platforms for seamless online shopping'] },
    // { title: 'Business Intelligence', description: ['Our BI solutions turn data into actionable insights for your business.'] },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    <section className='new-industries-we-serve container pt-36 xl:pt-48 pb-24 md:pb-36' id="services">
      <div className='new-iws-content'>
        <div className='new-iws-content_left'>
          {cards.map((card, index) => (
            <CardWithMotion key={index} title={card.title} description={card.description} isMobile={isMobile} />
          ))}
        </div>
        <div className='new-iws-content_right'>
          <div className='new-sticky-content'>
            <div className='new-industries-card'>
              <p className='new-text-white'> IT Support</p>
              <p className='new-text-white fs-30 montserrat-500'>
                <span className='montserrat-600'>"Our IT support keeps your systems running smoothly and efficiently"</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CardWithMotion = ({ title, description, isMobile }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const itemVariants = isMobile
    ? {
        hidden: { opacity: 0, y: 150 },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            ease: 'easeOut',
          },
        },
      }
    : {
        opacity: 1,
        y: 0,
      };

  return (
    <motion.div
      ref={ref}
      className='new-iws-details-card'
      variants={itemVariants}
      initial='hidden'
      animate={inView ? 'visible' : 'hidden'}
    >
      <div className='new-dot-div'></div>
      <h3 className='black-text dark:text-white fs-21 py-4' style={{ textAlign: 'center' }}>{title}</h3>
      <p className='black-text dark:text-white fs-14' style={{ textAlign: 'center' }}>
        {description.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {index < description.length - 1 && <br />}
          </React.Fragment>
        ))}
      </p>
    </motion.div>
  );
};

export default Industries1;
