import React from 'react'
const MarqueeImg1 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/14.png";
const MarqueeImg2 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/2.png";
const MarqueeImg3 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/18.png";
const MarqueeImg4 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/20.png";
const MarqueeImg5 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/21.png";
const MarqueeImg6 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/19.png";
const MarqueeImg7 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/16.png"
const MarqueeImg8 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/3.png";
const MarqueeImg9 =  "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/4.png"
const MarqueeImg10 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/17.png"
const MarqueeImg11 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/7.png"
const MarqueeImg12 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/8.png"
const MarqueeImg13 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/1.png"
const MarqueeImg14 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/5.png"
const MarqueeImg15 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/6.png"
const MarqueeImg16 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/9.png"
const MarqueeImg17 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/13.png"
const MarqueeImg18 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/10.png"

const MarqueeImg19 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/12.png"
const MarqueeImg20 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/22.png"
const MarqueeImg21 = "https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/11.png"

const Marquee = () => {
  return (
    <section className='marquee-section pb-36 md:pb-52'>
        <h2 className='heading-center'>Join the Ranks of Our Satisfied Clients</h2>
        <h3 className='subheading-center'>100+ and Counting</h3>
      <div className="marquee">
        <div className="marquee__group">
          <img src={MarqueeImg1} alt='client-logo'/>
          <img src={MarqueeImg2} alt='client-logo'/>
          <img src={MarqueeImg3} alt='client-logo'/>
          <img src={MarqueeImg4} alt='client-logo'/>
          <img src={MarqueeImg5} alt='client-logo'/>
          <img src={MarqueeImg21} alt='client-logo'/>
        </div>

        <div className="marquee__group">
          <img src={MarqueeImg6} alt='client-logo'/>
          <img src={MarqueeImg7} alt='client-logo'/>
          <img src={MarqueeImg8} alt='client-logo'/>
          <img src={MarqueeImg9} alt='client-logo'/>
          <img src={MarqueeImg10} alt='client-logo'/>
          
        </div>
      </div>

      ?

      <div className="marquee marquee--reverse">
        <div className="marquee__group">
          <img src={MarqueeImg11} alt='client-logo'/>
          <img src={MarqueeImg12} alt='client-logo'/>
          <img src={MarqueeImg13} alt='client-logo'/>
          <img src={MarqueeImg15} alt='client-logo'/> 
          <img src={MarqueeImg14} alt='client-logo'/>
        </div>

        <div className="marquee__group">
          <img src={MarqueeImg16} alt='client-logo'/>
          <img src={MarqueeImg17} alt='client-logo'/>
          <img src={MarqueeImg18} alt='client-logo'/>
          <img src={MarqueeImg19} alt='client-logo'/>
          <img src={MarqueeImg20} alt='client-logo'/>
          <img src={MarqueeImg21} alt='client-logo'/>
        </div>
      </div>
    </section>
  )
}

export default Marquee
