import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import OneStopSolution1 from "../components/OneStopSolution1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import QuantifyingTheData from "../components/QuantifyingTheData";
import BoostingYourBottomLine from "../components/BoostingYourBottomLine";
import DigitalMarketingServices1 from "../components/DigitalMarketingServices1";
import ITServices1 from "../components/ITServices1";
import BusinessAnalystics from "../components/BusinessAnalystics";

import SatisfiedClients from "../components/SatisfiedClients";
import FounderNote from "../components/Testimonialfounder";
import FounderNote1 from "../components/FounderNote1";



import ContactFrom1 from "../components/ContactForm1";
import Stories from "../components/Stories";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./NewHomepage.module.css";
import style from "./NewHomepage1.module.css";

import Testimonial1223 from "../components/Testimonial1223";
import { useEffect } from "react";
import DropdownMenu from "../components/DropdownMenu";
import ContactUsBar from "../components/ContactUsBar";
import ContactUsBar12 from "../components/ContactUsBar12";
import Testimonial1 from "../components/Testimonial1";
import { Link } from "react-router-dom";
import WhoWeAre1 from "../components/WhoWeAre1";
import Testimonial123 from "../components/Testimonial123";
import Testimonial1231 from "../components/Testimonial1231";
import { Helmet } from "react-helmet";
import BusinessAnalystics1 from "../components/BusinessAnalystics1";
import Testimonial from "../components/Testimonial";
import About from "../components/About";
import Marquee from "../components/Marquee";
import Industries from "../components/Industries";
import Industries1 from "../components/Industries1";
import IntentWeServe from "../pages/IntentWeServe"
import IntentWeServe1 from "../components/IntentWeServe1"

const NewHomepage = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/c6db88663b1c455f420c22391cadaad7.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Group+1520.png";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const HeroTitel = "Empowering your Online presence, Driving Actual Results";
  const HeroContent =
    "Join Techrowth, the best IT and Digital Marketing Company in Bangalore, to experience exceptional results and elevate your business to new levels with our result-oriented IT & marketing strategies.";
  const RecentText =
    "Wondering if you could be a part of a success story? Book a free consultation today!";
    const RecentText1 =
    "Be a Part of Techrowth Family!";
    const RecentText2 =
    "Experience Result-Driven Marketing";
    const RecentText3 =
    "Learn more about our IT Services";
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://techrowth.com/" />
                <title>IT & Digital Marketing Company in Bangalore | IT & Digital Marketing Agency</title>
                <meta name="description" content="Techrowth is a result-driven IT & Digital Marketing Company in Bangalore India. Contact us now to get in touch with our IT & Digital Marketing Consultants in Bangalore" />
                <meta name="keywords" content="Digital Marketing Company in Bangalore, Digital Marketing Agency in Bangalore, Online Marketing Company in Bangalore, Internet Marketing Company in Bangalore, Digital Marketing Companies in Bangalore, Digital Marketing Agency, Digital Marketing Company, Online Marketing Company, Internet Marketing Company Bangalore" />
            </Helmet>
    <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      
      <RecentResult />
      <OneStopSolution1 text={RecentText} btnText="Book a Consultation!" />
      <Marquee/>
      <SatisfiedClients show={false} />
      <div className={style.Testimonial}>
      <Testimonial1/>
      <Testimonial123/>

      </div >
      <div className={style.Testimonial1}>
      <Testimonial/>

      <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>

<ContactUsBar content={RecentText} btnTxt="Contact Us" />
</Link>
      </div>

      <div className={style.Testimonial1231}>
  
  
      </div>

{/* <IntentWeServe1 /> */}
   
      <AccelerateYourGrowth />
{/* <WhoWeAre1/> */}


<DigitalMarketingServices1 />
<div className={style.Testimonial1231}>
      <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>

<ContactUsBar content={RecentText2} btnTxt="Contact Us" style={{width:"20rem"}}/>
</Link>
      </div>
      
      {/* <BoostingYourBottomLine /> */}
      <Industries1 />
     
      <QuantifyingTheData />
  <Industries />
    
      {/* <BusinessAnalystics1 /> */}
      <div className={style.Testimonial1231}>
      <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>

<ContactUsBar content={RecentText3} btnTxt="Contact Us" style={{width:"20rem"}}/>
</Link>
      </div>
      <About/>
      <BusinessAnalystics show={true} onDisplay={true} titleDiplay={true} />

  
    <div className={style.test}>
      <Testimonial1223/>
    </div>
    <div className={style.test1}>
      <FounderNote/>
     

    </div>
      <ContactFrom1 />
     
      <ExploreOurCaseStudies />
    </div>
     <TrustedByBrands />
     </>
  );
};

export default NewHomepage;



