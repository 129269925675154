import { Link, useLocation } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import "animate.css";
import styles from "./Header.module.css";
import { useEffect, useState } from "react";
import DropdownMenu from "./DropdownMenu";
import DropdownMenu1 from "./DropdownMenu1";
import DropdownMenu2 from "./DropdownMenu2";
import { Helmet } from 'react-helmet';
import DropdownMenuRe from "./DropdownMenuRe"
import DropdownMenuRe1 from "./DropdownMenuRe1"
import DropdownMenuRe2 from "./DropdownMenuRe2"
import PopupComponent from "./PopupComponent"
import DropdownMenuIT from "./DropdownMenuIT";
import { useRef } from "react";

const Header = () => {
  const sectionRef = useRef(null);

  const handleClick = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const [popupVisible, setPopupVisible] = useState(false);

  const openPopup = () => {
    setPopupVisible(true);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown1 = () => {
    setIsOpen(!isOpen);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const toggleDropdown2 = () => {
    setIsOpen1(!isOpen1);
  };
  const location = useLocation();

  useEffect(() => {
    setIsDropdownOpen(false);
    setIsOpen(false);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
  }, [location]);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
    setIsOpen(false);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };
  const handleDataFromChild = (data) => {
    setIsDropdownOpen(data);
  };
  const handleMouseEnterIT = () => {
    setIsOpen(true);
    setIsDropdownOpen(false);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };
  const handleMouseEnterIT1 = () => {
    setIsOpen(false);
    setIsOpen1(true);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsDropdownOpen(false);
  };
  const handleMouseEnterIT2 = () => {
    setIsOpen(false);
    setIsOpen2(true);
    setIsOpen1(false);
    setIsOpen3(false);
    setIsDropdownOpen(false);
  };
  const handleMouseEnterIT3 = () => {
    setIsOpen(false);
    setIsOpen3(true);
    setIsOpen2(false);
    setIsOpen1(false);
    setIsDropdownOpen(false);
  };
  const handleMouseEnterMenu = () => {
    setIsOpen(false);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsDropdownOpen(false);
  };
  const handleDataFromChildIT = (data) => {
    setIsOpen(data);
  };
  const handleDataFromChildIT1 = (data) => {
    setIsOpen1(data);
  };
  const handleDataFromChildIT2 = (data) => {
    setIsOpen2(data);
  };
  const handleDataFromChildIT3 = (data) => {
    setIsOpen3(data);
  };
  return (
    <>

       <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11108316604"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11108316604');
          `}
        </script>
      </Helmet>
      <header className={styles.header} onMouseLeave={handleMouseEnterMenu}>
      {/* <header className={styles.header}> */}

        <div className={styles.topbar}>
          <div className={styles.topbar1} id="TopHeader">
            <Link to="/">
              <img className={styles.logo21Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo%402x.png" />
            </Link>
 
            <div className={styles.phonebutton}>
              <a className={styles.frameParent} href="tel:+918073162943">
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1420.svg"
                />
                <div className={styles.groupWrapper}>
                  <div className={styles.parent}>
                    <div className={styles.div}>+91 8073162943</div>
                    <div className={styles.support}>Sales</div>
                  </div>
                </div>
              </a>
              <a className={styles.frameGroup} href="tel:+919353937262">
                <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-23.svg" />
                <div className={styles.group}>
                  <div className={styles.div1}>+91 9353937262</div>
                  <div className={styles.sales}>Support</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.navigationBar} >
          <div className={styles.nav}>
            <div className={styles.revenubuttonParent}>
              <button className={styles.revenubutton}>
                <div className={styles.rectangleParent}>
                  <div className={styles.groupChild} />
                  <div className={styles.revenueDrivenForOutClientsParent}>
                    <div className={styles.revenueDrivenFor}>
                      Revenue driven for Our clients
                    </div>
                    <div className={styles.container}>
                      <div className={styles.div2}>$ 140,067,504</div>
                      <img
                        className={styles.groupItem}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-21.svg"
                      />
                    </div>
                  </div>
                </div>
              </button>
            <Link to="/contact-us">

              <button className={styles.loginbutton}>
                
                {/* <button className={styles.frameContainer}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-22.svg"
                  />
                  <div className={styles.clientLogin}>CLIENT LOGIN</div>
                </button> */}
              </button>
              </Link>            </div>
            <nav className={styles.navlinks}>
              <div className={styles.links}>
                <Link
                  to="/"
                  className={styles.analytics}
                  onMouseEnter={handleMouseEnterMenu}
                  style={{textDecoration:"none"}}
                >
                  Home
                </Link>

                <div className="dropdown" style={{ border: "none" }}>
                  <Link
                    to="/digital-marketing"
                    onMouseEnter={handleMouseEnter}
                    className={styles.digitalMarketing}
                    style={{
                      border: "none",
                      textDecoration:"none"
                    }}
                  >
                    Digital Marketing
                  </Link>
                </div>

                <div className="dropdown" style={{ border: "none" }}>
                  <Link
                    to="/it-services"
                    onMouseEnter={handleMouseEnterIT}
                    className={styles.digitalMarketing}
                    style={{
                      border: "none",
                      textDecoration:"none"
                    }}
                  >
                    IT Services
                  </Link>
                </div>
                {/* <div
                  className={styles.whoWeAre}
                  onMouseEnter={handleMouseEnterIT2}
                >
                  <Link to="/" style={{ color: "#000000",
                      textDecoration:"none" }}>
                  Analytics
                  </Link>
                </div> */}
           





                <div
                  className={styles.whoWeAre}
                  onMouseEnter={handleMouseEnterIT3}
                >
                  <Link  style={{ color: "#000000",
                      textDecoration:"none" }}>
                    Who We Are
                  </Link>
                </div>
                
                <div className="dropdown" style={{ border: "none" }}>
                  <Link
                    
                    to="/techrowth-knowledge"
                    className={styles.analytics}
                    onMouseEnter={handleMouseEnterMenu}
                    style={{textDecoration:"none"}}
                  >
                   Techrowth Knowledge
                  </Link>
                </div>

                <div className="dropdown" style={{ border: "none" }}>
                  <Link
                    
                    to="/blogs"
                    className={styles.analytics}
                    onMouseEnter={handleMouseEnterMenu}
                    style={{textDecoration:"none"}}
                  >
                   Blogs
                  </Link>
                </div>

                <Link to="/techrowth-portfolio"  style={{
                      border: "none",
                      textDecoration:"none"
                    }}>
                  <div className={styles.analytics}>Portfolio</div>
                </Link>
              </div>
              
              <button className={styles.navlinksInner}>
                <img className={styles.vectorIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/vector-1.svg" />
              </button>
            </nav>
            <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>
            <button className={styles.navbutton} onClick={handleClick}>
              <div className={styles.contactUs}>Contact Us</div>
            </button>
            </Link>
          </div>
   
        {isDropdownOpen && (
          <DropdownMenu
            onMouseEnter={handleMouseEnter}
            sendDataToParent={handleDataFromChild}
          />
        )}
        {isOpen && (
          <DropdownMenuIT
            onMouseEnter={handleMouseEnterIT}
            sendDataToParent={handleDataFromChildIT}
          />
        )}
           {isOpen1 && (
          <DropdownMenuRe
            onMouseEnter={handleMouseEnterIT1}
            sendDataToParent={handleDataFromChildIT1}
          />
        )}
              {isOpen2 && (
          <DropdownMenuRe1
            onMouseEnter={handleMouseEnterIT2}
            sendDataToParent={handleDataFromChildIT2}
          />
        )}
              {isOpen3 && (
          <DropdownMenuRe2
            onMouseEnter={handleMouseEnterIT3}
            sendDataToParent={handleDataFromChildIT3}
          />
        )}
        </div>
      </header>
      <header className={styles.header1} >
      <div className={styles.phonenav}>
      <Link to="/">
   
      <img className={styles.vectorIcon1} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/test1234.png" />



      </Link>

     <div className={styles.phonenav1}>



  
        <img className={styles.vectorIcon2} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/phone.png"     onClick={toggleDropdown} />
        {/* <div>
          <Link to="/">
      <img className={styles.vectorIcon3} alt="" 
        style={{ cursor: 'pointer' }} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/menu.png"   /> 
   </Link>
      </div> */}






      <div>
      <img className={styles.vectorIcon3} alt="" onClick={openPopup}
        style={{ cursor: 'pointer' }} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/menu.png"   /> 
      {popupVisible && <PopupComponent onClose={() => setPopupVisible(false)} />}
      </div>
      </div>
   
      </div>
      
      {isDropdownOpen && (
          <DropdownMenu1
            onMouseEnter={handleMouseEnter}
            sendDataToParent={handleDataFromChild}
          />
        )}
        {isOpen && (
          <DropdownMenu2
            onMouseEnter={handleMouseEnterIT}
            sendDataToParent={handleDataFromChildIT}
          />
        )}
    </header>

    </>
  );
};

export default Header;
