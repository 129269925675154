import styles from "./ContactForm1.module.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useRef,useEffect } from 'react';
import emailjs from 'emailjs-com';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
const redirectToThankYouPage = () => {
  window.location.href = "/Thankyou";
};

const ContactForm1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const form1 = useRef();
  const form2 = useRef();
  const handleWhatsAppRedirect = () => {
    // Replace the phone number with the desired WhatsApp number
  
    const phoneNumber = "+918073162943";
    const url = `https://wa.me/${phoneNumber}`;
  
    // Open the WhatsApp chat in a new window/tab
    window.open(url, "_blank");
  };
  const [value, setValue] = useState()
  const sendEmail = (e, formRef) => {
    e.preventDefault();
    const selectedServices = [];
    const checkboxes = formRef.current.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedServices.push(checkbox.value);
      }
    });
  
    // Prepare data to send in the email
    const emailData = {
      name: formRef.current.name.value,
      designation: formRef.current.designation.value,
      phoneno: formRef.current.phoneno.value,
      email: formRef.current.email.value,
      compname: formRef.current.compname.value,
      website: formRef.current.website.value,
      message: formRef.current.message.value,
      selectedServices: selectedServices.join(', '), // Combine selected services into a comma-separated string
    };
    emailjs.sendForm('service_ecyz8eo', 'template_8xq46kb', formRef.current, '-PZNCD8ehqiwS8RhW')
    // emailjs.sendForm('service_0ura04e', 'template_213qzbw', formRef.current, '5V6mQ1jA1-x6dKO3_')

      .then((result) => {
          console.log(result.text);
          e.target.reset();
      redirectToThankYouPage(); 

      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  
  return (
    <>
    <div  className={styles.contactform}>
      <div className={styles.contactformInner}>

        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-572%402x.png"
                />
                <div className={styles.contactUsParent}>
                  <div className={styles.contactUs}>{`Contact Us `}</div>
                  <div className={styles.mobileSupport9180731629Parent}>
                    <div className={styles.mobileSupport}>
                    <a href="tel:+918073162943" style={{color:"white", textDecoration:"none"}}>India: +91 80731 62943</a>
                    </div>
                    <div className={styles.mobileSupport}>
                    <a href="tel:+260767527931" style={{color:"white", textDecoration:"none"}}>Zambia: +260 767527931</a>
                    </div>
        
                     <div className={styles.mobileSupport}>
                    <a href="tel:+610402049350" style={{color:"white", textDecoration:"none"}}>Australia: +61 04020 49350</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+572123.svg"
                />
                <div className={styles.contactUsParent}>
                  <div className={styles.location}>Global Support</div>
                  <div className={styles.lusakaZambia}>
                  <a href="mailto:info@techrowth.com" target="_blank" style={{color:"white", textDecoration:"none"}}>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Frame+3050.svg" 
                />info@techrowth.com</a>
                  </div>
                  <div className={styles.lusakaZambia}>
                  <a href="https://join.skype.com/invite/pR5oMI4Q8bAy" target="_blank" style={{color:"white", textDecoration:"none"}}>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697123.svg" 
                />Skype Techrowth</a>
                  </div>
                  <div className={styles.bangaloreIndia}>
                  <a href="https://teams.live.com/l/invite/FEAzL5s01dTUWIILwE" target="_blank" style={{color:"white", textDecoration:"none"}}>
                  <img
                  className={styles.frameChild111}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2721123.svg"

                />Teams  Techrowth</a>
                  </div>
                  <div className={styles.bangaloreIndia} onClick={handleWhatsAppRedirect} >
                  <a  target="_blank" style={{color:"white", textDecoration:"none", cursor:"pointer"}}>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697-123.svg"
                />+91 935-393-7262</a>
                  </div>
              
                </div>
              </div>
              <div className={styles.flags}>
              <a href="https://goo.gl/maps/JMgLQkPa5RD25jFRA" target="_blank" style={{color:"white", textDecoration:"none"}}>
              <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/india.svg"
                /></a>
                  <a href="https://goo.gl/maps/xhU5DquTGJ3A7L217" target="_blank" style={{color:"white", textDecoration:"none"}}>
                <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/zam.svg"
                /></a>
                <a href="https://goo.gl/maps/NPLPMMsjs6co8QMn6" target="_blank" style={{color:"white", textDecoration:"none"}}>
                <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/aus.svg"
                /></a>
                </div>
            </div>
          </div>
          <div className={styles.takeAdvantageOfTheOpportunParent}>
            <div className={styles.takeAdvantageOfContainer + "mt-4"}>
              <p className={styles.takeAdvantageOf} style={{fontWeight:"500"}}>
             
                  {" "}
                Take the opportunity to work with the best IT & Digital Marketing Company in Bangalore. Contact us today!
                  
          
              </p>
            </div>
            <form ref={form1} onSubmit={(e) => sendEmail(e, form1)}>

            <div className={styles.frameParent1}>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                  <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    required
                  />
                    <input
                    className={styles.frameInput}
                    type="text"
                    name="designation"
                    required
                    placeholder="Designation"
                  />
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                <input
        className={styles.frameInput}
        type="number"
        placeholder="Official Contact No."
        name="phoneno"
      
        required
      />
                  <input
                    className={styles.frameInput}
                    type="email"
                    placeholder="Business Email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    required
                  />
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Company Name"
                    name="compname"
                  />
                  <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Website"
                    name="website"
       
                  />
            
                </div>
              </div>
              <div className={styles.squareRadioWrapper}>
  Interested Service -
  <label className={styles.squareRadioLabel} style={{gap:"3px"}}>
    <input
      type="radio"
      name="service"
      id="digitalmarketing"
      value="Digital_Marketing"
      required
    />
    Digital Marketing
  </label>
  <label className={styles.squareRadioLabel} style={{gap:"3px"}}>
    <input
      type="radio"
      name="service"
      id="itdevelopment"
      value="IT_Development"
      required
    />
    IT Development
  </label>
  <label className={styles.squareRadioLabel} style={{gap:"3px"}}>
    <input
      type="radio"
      name="service"
      id="analytics"
      value="Analytics"
      required
    />
    Analytics
  </label>
</div>



              <div className={styles.div}>
                <input
                  className={styles.child}
                  type="text"
                  name="message"
                  placeholder="How can we help?"
                />
              </div>
              <button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{borderRadius:"30px",padding:".8rem 3rem", background:"#0076A2", border:"none"}}>
              <input type="submit" className={styles.letsGetStarted} value="Let’s get Started!" />
              </button>
            </div>
            </form>
          </div>
        </div>
       
      </div>
    </div>











    <div className={styles.contactform777}>


    <div className={styles.contactform11}>
      <h4 style={{fontSize:"16px"}}>   <img
                  className={styles.frameChildd}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-572%402x.png"
                /> Contact Us </h4>
                <a href="tel:+918073162943" style={{color:"white", textDecoration:"none"}}><p>India: +91 80731 62943</p></a>
                <a href="tel:+260767527931" style={{color:"white", textDecoration:"none"}}><p>Zambia: +260 767527931</p></a>
                <a href="tel:+610402049350" style={{color:"white", textDecoration:"none"}}><p>Australia: +61 04020 49350</p></a>

                {/* <a href="mailto:info@techrowth.com" style={{color:"white"}}>
                <p>Email - info@techrowth.com</p></a> */}
                <h4  style={{fontSize:"16px"}}>Global Support</h4>
                <a href="mailto:info@techrowth.com" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Frame+3050.svg" 
                />info@techrowth.com</p></a>
                 <a href="https://join.skype.com/invite/pR5oMI4Q8bAy" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697123.svg" 
                />Skype Techrowth</p></a>
  <a href="https://teams.live.com/l/invite/FEAzL5s01dTUWIILwE" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                  <img
                  className={styles.frameChild111}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2721123.svg"

                />Teams  Techrowth</p></a>
                   <a  target="_blank" style={{color:"white", textDecoration:"none", cursor:"pointer"}}><p>
                  <img
                  className={styles.frameChild11}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697-123.svg"
                />+91 935-393-7262</p></a>
      </div>
      <div className={styles.contactform111}>
        <h4 className={styles.contactform8880}>Let's Connect</h4>
        <form ref={form2} onSubmit={(e) => sendEmail(e, form2)}>
        <div className={styles.frameParent1}>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                  <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Full Name"
                    name="name"

                    required
                  />
               
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
            
                <input
                    className={styles.frameInput}
                    type="text"
                    name="designation"
                    required
                    placeholder="Designation"
                  />
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
              

                <input
        className={styles.frameInput}
        type="number"
        placeholder="Official Contact No."
        name="phoneno"
   
        required
      />
                
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                

                <input
                    className={styles.frameInput}
                    type="email"
                    placeholder="Business Email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    required
                  />
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                

                <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Company Name"
                    name="compname"
                  />
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                

                <input
                    className={styles.frameInput}
                    type="text"
                    placeholder="Website"
                    name="website"
       
                  />
                </div>
              </div>
           <div className={styles.frameWrapper1}>
        <div className={styles.frameParent2} >
 
        <select className={styles.frameInput} name="service" required>
  <option value=""  disabled selected style={{color:"black"}}>
    Interested Service
  </option>
  <option value="Digital_Marketing">Digital Marketing</option>
  <option value="IT_Development">IT Development</option>
  <option value="Analytics">Analytics</option>
</select>
</div>
</div>

              <div className={styles.div}>
                <input
                  className={styles.child}
                  type="text"
                  name="message"

                  placeholder="How can we help?"
                />
              </div>
          
              <button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{borderRadius:"30px",padding:"0.6rem 1rem",marginTop:"1rem", background:"#0076A2", border:"none"}}>
               
                <input type="submit" style={{fontSize:"11px"}} className={styles.letsGetStarted} value="Let’s get Started!" />
              </button>
            </div>
            </form>
    </div>

    </div>
    
</>
  );
};

export default ContactForm1;
