import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case.module.css";
import styless from "../components/ContactUsBar.module.css";
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";

const CaseStudy1 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
        <div className={styles1.newHomepage}>
      <div className="container-fluid" style={{ padding: "2rem 2rem" }}>
        <div className={styles.resourcePage24}>
        <div className={styles.sliderParent}>
          
          <div className={styles.slider}>
            <div className={styles.groupParent}>
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
            </div>
          </div>
          <div className={styles.executionStrategy} >
             Our Case Studies:
            </div>
          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Boosting and Expanding sales `}</span>
                  <span className={styles.southIndianMarket}>
                  through Emotional Marketing.
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-111111.jpg" />
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Dairy Sector
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
                </div>
                <div className={styles.toOvercomeTheContainer}>
                  <p className={styles.theDairySectorWantedToBoo}>
                  The business wanted to boost their sales and expand their reach to the South Indian market.
                  </p>
                </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased sales by 47%
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>



          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Overcoming low sales and Increasing brand Visibility `}</span>
                  <span className={styles.southIndianMarket}>
                  through Rational Marketing.
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study2.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Aviation Sector
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The aviation company wanted to overcome its low sales and
                increase brand visibility while transitioning from the government
                to private sector.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased ticket sales by 21%
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Revitalizing Engagement through Social media tools `}</span>
                  <span className={styles.southIndianMarket}>
                  resulting in increased bookings.
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study3.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Transportation company.
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The transportation service company was struggling with low engagement for their AC buses in comparison to their Non-AC buses. Due to a lack of amenities and available comfort, ca using customers to choose the Non-AC option instead.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased overall engagement by 78%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Transforming slow speed website through `}</span>
                  <span className={styles.southIndianMarket}>
                  compression of file sizes.
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study4.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Banking Sector
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The banking website had low engagement among users, which is what the company wanted to be resolved.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased website loading speed by 81%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Enhancing B2B E-commerce
website for increased `}</span>
                  <span className={styles.southIndianMarket}>
                  Sales &
Inquiries
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study5.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    B2B E-commerce
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The business wanted to revamp their website for sales and
inquiries and wanted a website that could adequately handle their
incoming traffic and leave room for further infrastructure
improvements if needed.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased sales enquiry rates by 62%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>



          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`By using IT services and
Analytics, Mid-Sized IT Tech
Company `}</span>
                  <span className={styles.southIndianMarket}>
                  may increase
customer retention.
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study6.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Mid-Sized IT Tech Company
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The business aimed to improve customer retention. They identified
key issues, such as poor communication and customer service, and
were willing to shift focus towards software solutions.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased customer retention rate by 49%
                    </p>
                  </div>
                </div>

            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Promoting a new menu Item to `}</span>
                  <span className={styles.southIndianMarket}>
                  drive more sales for a hotel
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-71.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    A hotel chain
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The hotel chain was supposed to introduce a new menu item and wanted to promote it to their customers while highlighting its high nutritional value.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased sales by 77%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Enhancing Online Presence and
Customer Engagement for `}</span>
                  <span className={styles.southIndianMarket}>
                  an
Online Clothing Retailer
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study8.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Online Clothing Retailer
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                With an Economical Marketing Strategy the online clothing
retailer wanted to resolve their weak online presence, and attract
new customers to their website.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased traffic to their website by 30%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Implemented Digital Marketing Strategies for Increasing `}</span>
                  <span className={styles.southIndianMarket}>
                  Walk-ins in a Jewelry Store
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study9.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    A Jewelry business store.
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The jewelry store wanted to increase their walk-Ins through digital marketing.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    144% Increase in Store Walk-Ins.
                    </p>
                  </div>
                </div>

            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>




          <div className={styles.blog}>
            
          <div className={styles.theDairySectorContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                  <span>{`Maximizing Digital Customer
Acquisition `}</span>
                  <span className={styles.southIndianMarket}>
                  in the Banking Sector
                  </span>
                </p>
              </div>
              <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-101.jpg" />
              <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>BUSINESS:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    A Banking Sector
                    </p>
                  </div>
                </div>
                <div className={styles.executionStrategy}>
                Business Statement:
              </div>
              <div className={styles.toOvercomeTheContainer}>
                <p className={styles.theDairySectorWantedToBoo}>
                The business wanted to increase their number of digital account
holders through new customer acquisition and reduce the cost
associated with digital marketing facilities.
                </p>
              </div>
               
                <div className="d-flex align-items-center">
                  <div className={styles.executionStrategy+" pr-2"}>Impact:</div>
                  <div className={styles.toOvercomeTheContainer}>
                    <p className={styles.theDairySectorWantedToBoo}>
                    Increased new account holders by 18%.
                    </p>
                  </div>
                </div>
            <div className={styles.blogInner}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/maximizing-digital-customer-acquisition-in-the-banking-sector" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
            <img className={styles.blogItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-47.svg" />
          </div>
     
          <div />
        </div>
          
        <div className={styles.sidebar}>
            <div className={styles.sidebarform}>
              <div className={styles.frameParent8}>
                <div className={styles.letsConnectParent}>
                  <div className={styles.executionStrategy}>Let’s Connect</div>
                  <div className={styles.frameParent9}>
                    <input
                      className={styles.frameInput}
                      type="text"
                      placeholder="Full Name"
                      required
                    />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Designation"
                    />
                    <input
        className={styles.frameInput}
        type="text"
        placeholder="Official Contact No."
        name="phoneno"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
      />
              <input
                    className={styles.frameChild7}
                    type="email"
                    placeholder="Business Email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Company Name"
                    />
                     <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Website"
                    />
                    <div className={styles.squareRadioWrapper}>
              <p style={{color:"var(--blue)"}}>Interested Service -</p>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="digitalmarketing"
              value="Digital_Marketing"
              required
            />
            Digital Marketing
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="itdevelopment"
              value="IT_Development"
              required
            />
            IT Development
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="analytics"
              value="Analytics"
              required
            />
            Analytics
          </label>
        </div>
                    <input
                      className={styles.frameChild10}
                      type="text"
                      placeholder="Message"
                    />
                  </div>
                </div>
                <div className={styles.frameWrapper4}>
                  <button className={styles.submitWrapper}>
                    <div className={styles.submit}>Submit</div>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.sidebarcontactdetails}>
              <div className={styles.sidebarcontactdetailsInner}>
                <div className={styles.frameParent10}>
                  <div className={styles.frameWrapper5}>
                    <div className={styles.frameParent11}>
                      <img
                        className={styles.frameChild1}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-572%402x.png"
                      />
                      <div className={styles.contactUsParent}>
                        <div
                          className={styles.executionStrategy}
                        >{`Contact Us `}</div>
                                  <a href="tel:+918073162943" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>India: +91 80731 62943</p></a>
          <a href="tel:+260767527931" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Zambia: +260 767527931</p></a>
          <a href="tel:+610402049350" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Australia: +61 04020 49350</p></a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineDiv} />
                  <div className={styles.contactform11}>

                    <h4>Global Support</h4>
                    <a href="mailto:info@techrowth.com" target="_blank" style={{color:"white", textDecoration:"none"}}><p style={{fontSize:"13px"}}>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Frame+3050.svg" 
                    />info@techrowth.com</p></a>
                    <a href="https://join.skype.com/invite/pR5oMI4Q8bAy" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697123.svg" 
                    />Skype Techrowth</p></a>
                    <a href="https://teams.live.com/l/invite/FEAzL5s01dTUWIILwE" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild111}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2721123.svg"

                    />Teams  Techrowth</p></a>
                      <a  target="_blank" style={{color:"white", textDecoration:"none", cursor:"pointer"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697-123.svg"
                    />+91 935-393-7262</p></a>
                    </div>




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default CaseStudy1;
