import React, { Component } from 'react';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      active: this.props.active,
      direction: ''
    };
  }

  moveLeft = () => {
    const newActive = this.state.active - 1;
    this.setState({
      active: newActive < 0 ? this.state.items.length - 1 : newActive,
      direction: 'left'
    });
  };

  moveRight = () => {
    const newActive = (this.state.active + 1) % this.state.items.length;
    this.setState({
      active: newActive,
      direction: 'right'
    });
  };

  generateItems() {
    const items = [];
    for (let i = this.state.active - 2; i <= this.state.active + 2; i++) {
      let index = i;
      if (i < 0) {
        index = this.state.items.length + i;
      } else if (i >= this.state.items.length) {
        index = i % this.state.items.length;
      }
      const level = this.state.active - i;
      items.push(<Item key={index} id={this.state.items[index]} level={level} />);
    }
    return items;
  }

  render() {
    return (
      <div id="carousel" className="noselect">
        <div className="arrow arrow-left" onClick={this.moveLeft}>
          <i className="fi-arrow-left"></i>
        </div>
        <div className="items-container">
          {this.generateItems()}
        </div>
        <div className="arrow arrow-right" onClick={this.moveRight}>
          <i className="fi-arrow-right"></i>
        </div>
      </div>
    );
  }
}

class Item extends Component {
  render() {
    const className = `item level${this.props.level}`;
    return <div className={className}>{this.props.id}</div>;
  }
}

export default Carousel;
