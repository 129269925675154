import styles from "./SatisfiedClients.module.css";

const SatisfiedClients = ({show}) => {
  return (
    <>
    
    <div className={styles.satisfiedClients1}>
      <h6 className={styles.satisfiedClients2}>Join the Ranks of Our Satisfied Clients</h6>
      <h3 className={styles.satisfiedClients3} >100+ and Counting</h3>
    <div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>

      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem", width:"8rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/IZB.png"/>
      
      <img className={styles.n1Icon1} alt="" style={{width:"7rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/c2.png"/>
      </div>
    <div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>
     


      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem",width:"3.5rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/c3.png"/>
 
      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/09.png"/>
      <img className={styles.n1Icon1} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/10.png"/>
</div>

<div className={styles.satisfiedClients4} >
     

     <img className={styles.n1Icon1} alt="" style={{width:"6rem", marginRight:"1rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/2.png"/>
     <img className={styles.n1Icon1} alt="" style={{width:"6rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/16.png"/>


</div>
<div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>

<img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem", width:"8rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/14.png"/>

<img className={styles.n1Icon1} alt="" style={{width:"7rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/15.png"/>
</div>



    </div>
    </>
  );
};

export default SatisfiedClients;
