import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"5rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">
<div className="row">
<div className="col-lg-8">
<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Transforming slow speed website through compression of file sizes.
</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full img-fluid" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study4.png" alt="D" width="800" height="422"  sizes="(max-width: 800px) 100vw, 800px"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
Banking Sector</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The banking website had low engagement among users, which is what the company wanted to be resolved.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>
Increased website loading speed by 81%.</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>

<p><strong>1) Fixed Redirecting and Server Issues:</strong></p>
<ul>

<li>Identified and fixed the website's redirecting and server issues to improve performance.</li>
</ul>
<p><strong>2) Optimization for Mobile and Desktop:</strong></p>
<ul>
<li>Optimized the website for both mobile and desktop versions, ensuring users have a seamless experience.</li>


</ul>
<p><strong>3) Optimized Image Sizes:</strong></p>
<ul>
<li>Optimized images on the website to reduce the size and improve loading speed.</li>


</ul>

<p><strong>4) Content Delivery Network (CDN) Implementation:</strong></p>
<ul>
<li>Implemented a CDN to optimize the delivery of static files to website visitors.</li>


</ul>
<p><strong>5) Implementation of GZip Compression:</strong></p>
<ul>
<li>Implemented GZip compression on HTTP servers to minimize the size of HTTP responses for certain file types.</li>


</ul>
<p><strong>6) Minification and Combination of Files:</strong></p>
<ul>
<li>Minified and combined CSS, JavaScript, and HTML files to reduce server requests and improve loading speed.</li>


</ul>
<p><strong>7) Reduction of Plugins:</strong></p>
<ul>
<li>Reduced the number of plugins to improve <Link to="https://techrowth.com/it-services/it-development/website-development-service">website performance.</Link></li>


</ul>
<p><strong>8) Website Caching:</strong></p>
<ul>
<li>Implemented caching to store frequently accessed data in the user's browser, reducing server requests and improving website performance.</li>


</ul>
<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>

<li>Through implementing various optimizations, the website's loading speed increased by 81%.</li>
<li>Increase in website engagement increased by 73%.</li>




</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li>Content Delivery Network (CDN) to efficiently deliver website content to users in different locations.</li>
<li>Google Search Console to analyze <Link to="https://techrowth.com/it-services/it-development/website-development-service">website performance</Link>, monitor site health, and improve visibility in search results.</li>
<li>Compression software to reduce file sizes on website and improve load times.</li>
<li>Website caching to store frequently accessed data for faster retrieval and improving <Link to="https://techrowth.com/it-services/it-development/website-development-service">website speed.</Link></li>




</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>
The customer used a budget of $31K which made their vision a possibility, and lead to a number of benefits.</li>


</ul>

</div>
<div id="secondary" className="widget-area col-sm-4" role="complementary" style={{borderLeft:"1px solid #e9eef4", height:"1670px"}}>

<div className="sidebar">
<div className="widgets case_studysidebar">
</div>
<div className="widgets">
<aside id="custom-post-type-recent-posts-7" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Recent Case Studies
</h5>			<ul style={{color:"#0076a2" }}>
<li>
<Link to="/discover-why-startups-shine-with-a-strong-social-media" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Discover Why Startups Shine With a Strong Social Media Presence - Insights for Success</p></Link>
</li>
<li>
<Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
               
              
               <p style={{color:"#0076a2"}}>Boosting and Expanding sales through Emotional Marketing.</p>  </Link>
</li>
<li>
<Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Overcoming low sales and Increasing brand Visibility through Rational Marketing.</p></Link>
</li>

<li>
<Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Revitalizing Engagement through Social media tools resulting in increased bookings.</p></Link>
</li>
<li>
<Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing B2B E-commerce
website for increased Sales &
Inquiries.</p></Link>
</li>
<li>
<Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>By using IT services and
Analytics, Mid-Sized IT Tech
Company may increase
customer retention.</p></Link>
</li>
</ul>
</aside>			<aside id="custom-post-type-recent-posts-6" className="widgets widget_recent_entries"><h5 style={{color:"#0076a2", fontWeight:"600" }}>Most Saved Case Studies</h5>			
			<ul style={{color:"#0076a2" }}>

<li>
<Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Promoting a new menu Item to drive more sales for a hotel.</p></Link>
</li>
<li>
<Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Enhancing Online Presence and
Customer Engagement for an
Online Clothing Retailer.</p></Link>
</li>
<li>
<Link to="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Implemented Digital Marketing Strategies for Increasing Walk-ins in a Jewelry Store.</p></Link>
</li>
<li>
<Link to="/maximizing-digital-customer-acquisition-in-the-banking-sector" className="text-white" style={{textDecoration:"none"}}>

<p style={{color:"#0076a2"}}>Maximizing Digital Customer
Acquisition in the Banking Sector.</p></Link>
</li>
<li>
<Link to="/role-of-ai-in-digital-marketing" className="text-white" style={{textDecoration:"none"}}>
         <p style={{color:"#0076a2"}}>Role of AI in Digital Marketing</p> </Link>
</li>
</ul>
</aside>
</div>
</div>
</div>
</div>
</div>
</section>
</section>
</div>


    </>
  );
};

export default Test;
